import { createSelector, createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
  data: null,
  darkMode: false,
};

export const themeSlice = createSlice({
  name: "Theme",
  initialState,
  reducers: {
    themeSuccess: (theme, action) => {
      theme.data = action.payload;
    },
    changeMode: (theme, action) => {
      theme.darkMode = action.payload;
    },
  },
});

export const { themeSuccess, changeMode } = themeSlice.actions;
export default themeSlice.reducer;

// selector
export const themeData = createSelector(
  (state) => state?.Theme?.data,
  (data) => data
);

export const isDarkMode = createSelector(
  (state) => state?.Theme?.darkMode,
  (darkMode) => darkMode
);
