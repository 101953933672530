// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    lat: '35.85175976473775',
    lng: '14.486153704827611',
    locationAddress: 'Malta International Airport',
    modalOpen: false,
  },
  reducers: {
    setLatitude: (state, action) => {
      state.lat = '35.85175976473775';
    },
    setLongitude: (state, action) => {
      state.lng = '14.486153704827611';
    },
    locationAddressData: (state, action) => {
      state.locationAddress = 'Malta International Airport';
    },
    setModalOpen: (state, action) => {
      state.modalOpen = false;
    },
    setModalClose: (state, action) => {
      state.modalOpen = true;
    },
  },
});

export const { setLatitude, setLongitude, locationAddressData, setModalOpen, setModalClose } =
  locationSlice.actions;

export default locationSlice.reducer;
