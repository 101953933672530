import React, { useEffect } from "react";
import Footer from "./Footer";
import { darkTheme, lightTheme } from "../../Theme";
import { Paper } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./Header/Header";
import { LayoutProps } from "../../typescriptTypes/globalTypes";
import { changeMode, isDarkMode } from "../../redux/Theme";
import { useDispatch, useSelector } from "react-redux";

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const darkMode = useSelector(isDarkMode);
  const dispatch = useDispatch();

  useEffect(() => {
    if (darkMode) {
      dispatch(changeMode(true));
    }
  }, [dispatch, darkMode]);

  const handleChangeLight = (): void => {
    dispatch(changeMode(false));
  };

  const handleChangeDark = (): void => {
    dispatch(changeMode(true));
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Paper>
        <Header
          check={darkMode}
          changeLight={handleChangeLight}
          changeDark={handleChangeDark}
        />
        <div className="layout-style">{children}</div>
        <Footer />
      </Paper>
    </ThemeProvider>
  );
};

export default Layout;
