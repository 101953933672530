// ** Redux Imports
import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  settings: [],
  paymentMode: "",
};

export const authSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setPaymentMode: (state, action) => {
      state.paymentMode = action.payload;
    },
  },
});

export const { setSettings, setPaymentMode } = authSlice.actions;

export default authSlice.reducer;

export const paymentMode = createSelector(
  (state) => state?.Settings?.paymentMode,
  (paymentMode) => paymentMode
);