// ** Redux Imports
import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  promocode: [],
  selectedPromoCode: null,
};

export const promoSlice = createSlice({
  name: "promocode",
  initialState,
  reducers: {
    setPromoCode: (state, action) => {
      state.promocode = action.payload;
    },
    setSelectedPromocode: (state, action) => {
      state.selectedPromoCode = action.payload;
    },
    resetStatePromo: (state) => {
      return initialState;
    },
  },
});

export const { setPromoCode, setSelectedPromocode, resetStatePromo } = promoSlice.actions;

export default promoSlice.reducer;

export const selectedPromoCodeData = createSelector(
  (state) => state?.Promocode?.selectedPromoCode,
  (selectedPromoCode) => selectedPromoCode
);
